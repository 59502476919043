<template>
    <div class="letterWrapper">
        <top-search></top-search>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="breadCrump">
                        <span>
                            <router-link to="/">
                                Startseite
                            </router-link>
                        </span>
                        |
                        <span>
                            <router-link :to="{ name: 'cluster', params: {cluster: $route.params.cluster } }">
                                {{$route.params.cluster}}
                            </router-link>
                        </span>
                        |
                        <span>
                            <router-link :to="{ name: 'class', params: {cluster: $route.params.cluster,class: $route.params.class } }">
                                {{info.class}}
                            </router-link>
                        </span>
                        |
                        <span>
                            {{info.letter}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" v-show="loadComplete == false">
                <div class="col-md-12">
                    <div class="loadingBox">
                        <div class="row">
                            <div class="col-lg-12 text-center">
                                <br><br>
                                <b>{{$route.params.cluster}} werden geladen...</b>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="loadingBar" style="position: absolute;left:50%;top:15px;transform: translateX(-50%);">
                                    <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="loadComplete == true">
                <div class="col-md-12">
                    <div class="letterWraper" >
                        <div class="row">
                            <div class="col-sm-4" v-for="item in letters" :key="item.id" >
                                <div class="card" >
                                    <div class="card-body" >
                                        <h5 class="card-title">{{item.item_title | str_limit(20)}}</h5>
                                        <p class="card-text" style="min-height: 130px">{{item.item_plaintext | str_limit(150)}}</p>
                                        <div class="text-center">
                                            <span class="btn btn-primary ">
                                                <router-link :to="{ name: 'preview', params: {cluster: item.cluster_formated,class: item.class_1_formated,letter: item.item_letter,item: item.url_id } }">
                                                    Arbeit ansehen
                                                </router-link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <share-collect></share-collect>
    </div>
</template>

<script>
    import TopSearch from "../components/TopSearch";
    import ShareCollect from "../components/ShareCollect";
    import axios from "axios";
    export default {
        name: 'Letter',
        components: {ShareCollect, TopSearch},
        data: function () {
            return {
                loadComplete : false,
                info: {},
                letters: []
            }
        },
        methods:{
            getItems: function () {
                axios.get('https://api.pausenhof.de/letter/list/'+this.$route.params.letter,
                    {
                        params:{
                            class:this.$route.params.class,
                            cluster:this.$route.params.cluster
                        },
                        headers:{'authorization': '3241451345'}}
                        ).then(response => {
                    this.letters = response.data.content;
                    this.info = response.data.info;
                    this.loadComplete = true;
                });
            }
        },
        created () {
            this.getItems();
        },
    }
</script>

<style scoped>
    .searchWraper h1{
        font-family: 'Londrina Shadow';
        color:white;
        font-size: 3.5rem;
    }
    .letterWraper .btn-primary{
        background:#f76d6d;
        border-color:#f76d6d;
        color:#fff !important;
    }
    .letterWraper .btn-primary a{
        color:#fff !important;
    }
    .letterWraper .btn-primary a:hover{
        text-decoration: none !important;
    }
    .letterWraper .card{
        margin-bottom: 15px;
    }
    .letterWraper{
        padding:25px 0;
    }

</style>

